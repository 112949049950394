/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --BHP-Primary-LightGreen2: #f7f7f7;
  --SC_Primary_1: #802ab7;
  --BHP-Primary-PageBackground: #f8fafc;
  --on-primary-color: #ffffff;
  --primary-color: linear-gradient(0deg,
      rgba(0, 0, 0, 0.77) 0%,
      rgba(0, 0, 0, 0.77) 100%),
    linear-gradient(91deg, #c700d3 -27.31%, #0060ff 158.43%);
  --secondary-color: #802ab7;
  --on-secondary-color: #ffffff;
  --background: #c9c9c9;
  --dropdown-hover-bg-color: #eee;
  --font-color: #000;
  --border-color: #c9c9c9;
  --overlay-color: rgb(0 0 0/18%);
  --bhp-primary-grey-1: #c9c9c9;
  --bhp-text-tc-3: #000000;

  --bhp-primary-light-green-2: linear-gradient(91deg,
      rgba(199, 0, 211, 0.06) -27.31%,
      rgba(0, 96, 255, 0.06) 158.43%);
  --greys-grey-2: #666;
  --section-heading: #802ab7;
  --btn-background: linear-gradient(91deg,
      rgba(199, 0, 211, 0.03) -27.31%,
      rgba(0, 96, 255, 0.03) 158.43%);
  --btn-border: #c700d3;
  --SC_Primary_Light_Gradient: #c700d3, #0060ff;
}

/* DARK THEME STYLES */
.dark-theme {
  --primary-color: #000000;
  --on-primary-color: #ffffff;
  --secondary-color: #968c8c;
  --on-secondary-color: #ffffff;
  --background: #eee;
  --dropdown-hover-bg-color: #e4eded;
  --font-color: #000000;
  --border-color: #c9c9c9;
}

@font-face {
  font-family: "KantarBrown-Regular";
  src: url("/assets/fonts/Kantar Brown/KantarBrown-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "KantarBrown-Regular";
  src: url("/assets/fonts/Kantar Brown/KantarBrown-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "KantarBrown-Regular";
  src: url("/assets/fonts/Kantar Brown/KantarBrown-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "KantarBrown-Regular";
  src: url("/assets/fonts/Kantar Brown/KantarBrown-Thin.otf") format("opentype");
  font-weight: 100;
}

/*@font-face {
  font-family: "KantarBrownWeb-Regular";
  src: url("/assets/WebFonts/Kantar_Web_Fonts/css/fonts/KantarBrownWeb-Regular.eot") format("opentype");
}*/
html {
  font-size: 16px;

}

@media screen and (min-width: 1500px) {
  html {
    font-size: 20px !important;
  }
}

body {
  background: var(--BHP-Primary-PageBackground, #f8fafc);
  color: var(--font-color);
  transition: all ease-in-out 400ms;
  font-family: "KantarBrown-Regular", sans-serif;
  /* min-width: 1225px;
  min-height: 596px;
  position: relative;*/
  width: 100%;
  height: 100%;
  position: absolute;
  min-width:1200px;
  min-height: 520px;
}

.hr-line {
  border-top: 1px solid var(--secondary-color);
  margin: 10px 0;
}

input[type="text"],input[type="number"] {
  font-size: 0.7rem;
  border-radius: unset;
  padding: 1% 3%;
  border: 1px solid #c9c9c9;
  font-family: "KantarBrown-Regular", sans-serif;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* PREVIEW SELECTION PANEL ---STARTS HERE---*/
/* ===== Scrollbar CSS ===== */
/* Firefox */
/* * {
  scrollbar-width: auto;
  scrollbar-color: var(--secondary-color) var(--background);
} */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--background);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 5px;
  border: 1px solid var(--background);
}

app-header-nav-bar {
  width: 100%;
  height: 7%;
  display: block;
}

app-header-brandlogo {
  width: 15%;
}

app-header-module-list,
app-header-user-project-menu-list {
  width: 68%;
}

app-header-settings {
  width: 17%;
}

app-header-nav-text {
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  width: 16%;
}

app-header-home {
  height: 100%;
  display: flex;
  width: 83%;
  justify-content: right;
  margin-right: 1%;
}

.summary-label {
  color: #000000;
  font-weight: 700;
}

.grey-separator {
  color: #b3b3b3;
}

.summary-label-value {
  color: #000000;
}

.sigHigher {
  color: green;
}

.sigLower {
  color: red;
}

.red {
  color: red;
}

.green {
  color: green;
}

.CP {
  cursor: pointer;
}